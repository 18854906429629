import { webshopBerlesInfoCards } from "../../service-info-card-interface/used_by/webshop-berles-cards"
import { Reference } from "../reference-interface"

export const VideojatekBolt : Reference = {
  monitorPic: "/images/imac_pits_template.png",
  siteName: "Videójátékbolt.hu webshop",
  siteUrl: "https://www.videojatekbolt.hu/",
  cards: webshopBerlesInfoCards,
  referencePics: [
    "/images/refs/pl/ref_color.jpg",
    "/images/refs/pl/ref.jpg",
    "/images/refs/pl/ref_color.jpg",
  ],
  pageFunctions: [
    "Octopus 8 ERP-vel összekötött webshop",
    "Egyedi webshop design",
    "Önálló webshop, dedikált adminfelülettel",
    "Bérelt, nagy terhelésre optimalizált tárhely",
    "Folyamatos ügyfélszolgálat",
    "SEO optimalizálás" ,
  ],
  reversePageFunctions: false,
}
