import { ServiceInfoCardChildInterface } from "../service-info-card-interface"

const berles1: ServiceInfoCardChildInterface = {
  bigImg: false,
  boldDescription: "A webshopok már a kereskedelem jelene, ne maradjon le az extra bevételről. Próbálja ki most a legegyszerűbben és az eredmény magáért fog beszélni",
  description: "Az online tér végtelenségében sokkal nagyobb közönséget tud elérni webáruházával. Mi ennek megvalósításában segítünk, lépésről lépésre",
  displayXo: true,
  reverse: false,
  title: "Miért vágj bele?",
  picture: "/images/services/berles_card_1.jpg"
}

const berles2: ServiceInfoCardChildInterface = {
  bigImg: false,
  picture: "/images/services/berles_card_2.jpg",
  boldDescription: "Több mint 10 év fejlesztői múlt és a sok partnerrel együtt szerzett üzleti tapasztalataink alapján hoztuk létre a legmodernebb és leggyorsabb programnyelvben az Alphapro webshopot\n",
  description: "Mindig az ügyfél igényeit tartjuk szem előtt, ezért a piac legjobb szoftvereivel és szakembereivel segítjük, hogy automatizálva legyenek a folyamatok",
  displayXo: false,
  reverse: true,
  title: "Válassz minket!"
}

const berles3: ServiceInfoCardChildInterface = {
  bigImg: false,
  picture: "/images/services/berles_card_3.jpg",
  boldDescription: "Töltse fel a termékeket saját online kereskedői felületén keresztül, ahol valós időben láthatja a tranzakciókat és hozzáférést adhat csapatának\n",
  description: "Spórolja meg a nagy befektetést az online marketingbe, a webhely karbantartásába és a webtárhely-szolgáltatásba amivel mi foglalkozunk",
  displayXo: false,
  reverse: false,
  title: "Indulás percek alatt"
}

export const webshopBerlesInfoCards = [
  berles1,
  berles2,
  berles3
]







